import { PageProps } from 'gatsby';
import React from "react"

import StudentInfo from '../../../components/StudentInfo'
import { StudentPageContextProvider } from "../../../components/StudentInfo/context";
import StudentPageState from "../../../components/StudentInfo/state/StudentPageState";

const StudentPage = (props: PageProps) => {
  if (typeof window === "undefined") return null

  return <StudentPageContextProvider>
    <StudentPageState.Provider>
      <StudentInfo username={props.params.username} />
    </StudentPageState.Provider>
  </StudentPageContextProvider>
}

export default StudentPage
